// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedankt-js": () => import("./../../../src/pages/bedankt.js" /* webpackChunkName: "component---src-pages-bedankt-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/defaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-featured-post-js": () => import("./../../../src/templates/featuredPost.js" /* webpackChunkName: "component---src-templates-featured-post-js" */),
  "component---src-templates-job-post-js": () => import("./../../../src/templates/jobPost.js" /* webpackChunkName: "component---src-templates-job-post-js" */)
}

